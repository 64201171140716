import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public static readonly LOGIN_URL = `https://${environment.domainName}.auth.${environment.region}.amazoncognito.com/login?redirect_uri=${environment.redirectUrl}&response_type=token&client_id=${environment.clientId}`;
    public static readonly LOGOUT_URL = `https://${environment.domainName}.auth.${environment.region}.amazoncognito.com/logout?logout_uri=${environment.redirectUrl}&client_id=${environment.clientId}`;
    private idToken: string;
    private readonly idTokenName: string = 'cjwt';
    private readonly jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(private router: Router) {
        this.idToken = JSON.parse(localStorage.getItem(this.idTokenName));
    }

    login(idToken: string) {
        this.idToken = idToken;
        localStorage.setItem(this.idTokenName, JSON.stringify(this.idToken));
        this.router.navigate(['/home']);
    }

    logout() {
        localStorage.removeItem(this.idTokenName);
        document.location.href = AuthService.LOGOUT_URL;
    }

    isLoggedIn() {
        return this.idToken != null && !this.jwtHelper.isTokenExpired(this.idToken);
    }

    getToken(): string {
        return this.idToken;
    }
}
