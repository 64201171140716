<mat-card class="main-panel-style">
    <mat-card-header>
        <div>
            Integration
            <mat-form-field appearance="outline">
                <mat-select (ngModelChange)="onIntegrationChange($event)" [(ngModel)]="selectedIntegration"
                            name="integration" placeholder="Integrations">
                    <mat-option *ngFor="let integration of allIntegrations" [value]="integration">
                        {{integration}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card-header>

    <mat-card-content *ngIf="selectedIntegration && settings && settings.length > 0">
        <app-save-reset-buttons (onResetClick)="formToInitialValues()"
                                (onSaveClick)="onSubmit()"
                                [formButtonsDisabled]="formButtonsDisabled"></app-save-reset-buttons>
        <form (keydown.enter)="$event.preventDefault()" [formGroup]="settingsFormGroup">
            <app-info-item *ngFor="let element of settings" [setting]="element"
                           [settingsArrayFormGroup]="settingsFormGroup"
                           id="{{element.name}}"></app-info-item>
        </form>
        <app-save-reset-buttons (onResetClick)="formToInitialValues()"
                                (onSaveClick)="onSubmit()"
                                [formButtonsDisabled]="formButtonsDisabled"></app-save-reset-buttons>
    </mat-card-content>
</mat-card>
