import {Injectable} from '@angular/core';
import {Setting} from '../../models/setting';
import {TabIdentifier} from '../../enums/tab-identifier';
import {IntegrationIdentifier} from '../../enums/integration-identifier';

@Injectable({
  providedIn: 'root'
})
export class SettingFilterService {

  public filterByTabId(allElements: Setting[], tabId: TabIdentifier): Setting[] {
    const filtered = allElements.filter(e => e.displayData.displayIdentifiers.tabId === tabId);
    return filtered ? filtered : [];
  }

  public filterByIntegration(allElements: Setting[], integration: string): Setting[] {
    const filtered = allElements.filter(setting => {
      const integrationIds = setting.displayData.displayIdentifiers.integrationIds;
      const searchResult = integrationIds.find((element) => IntegrationIdentifier[element] === integration);
      return !!searchResult;
    });
    return filtered ? filtered : [];
  }

}
