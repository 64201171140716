import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NewLegalFirmPopupComponent} from '../popup/new-legal-firm-popup/new-legal-firm-popup.component';
import {FirmDetails} from '../../core/models/firm-details';
import {MatSelectChange} from '@angular/material/select';
import {LegalFirmService} from '../../core/services/legal-firm/legal-firm.service';
import {environment} from '../../../environments/environment';
import {Setting} from '../../core/models/setting';
import {IntegrationStatus} from '../../core/enums/integration-status';
import {StatusPopupComponent} from '../popup/status-popup/status-popup.component';
import {IntegrationIdentifier} from '../../core/enums/integration-identifier';
import {SettingService} from "../../core/services/setting/setting.service";
import {SettingDTO} from "../../core/dto/setting-dto";


@Component({
    selector: 'app-legal-firm-control-bar',
    templateUrl: './legal-firm-control-bar.component.html',
    styleUrls: ['./legal-firm-control-bar.component.css']
})
export class LegalFirmControlBarComponent implements OnInit {

    @Input('refreshSettingsEvent')
    refreshSettingsEvent: EventEmitter<Setting[]>;

    closed: any;
    legalFirms: FirmDetails[];
    firmLoading = true;
    searchText: any = '';
    status: IntegrationStatus = IntegrationStatus.UNDEPLOYED;
    statusButtonColor: string;
    legalFirmIntegration: IntegrationIdentifier;

    constructor(private dialog: MatDialog,
                private legalFirmService: LegalFirmService,
                private settingService: SettingService) {
    }

    ngOnInit(): void {
        if (!this.refreshSettingsEvent) {
            throw new Error('refreshSettingsEvent input is required');
        }

        this.legalFirmService.getAllLegalFirms()
            .subscribe(this.updateLegalFirmList());

        this.refreshSettingsEvent
            .subscribe((settings: Setting[]) =>
                this.reactOnRefreshSettingsEvent(settings))

    }

    openAddNewLegalFirmDialog() {
        const dialogRef = this.dialog.open(NewLegalFirmPopupComponent, {
            width: '400px',
            data: {}
        });

        dialogRef.componentInstance.newFirmCreatedEvent
            .subscribe(newFirm => this.legalFirms.push(newFirm));

        dialogRef
            .afterClosed()
            .subscribe(formData => {
                this.closed = formData;
                dialogRef.componentInstance.newFirmCreatedEvent.unsubscribe()
            });
    }

    onSelectionChange(event: MatSelectChange) {
        this.legalFirmService.setLegalFirm(<FirmDetails>event.value);
    }

    showSettingsButton(): boolean {
        return !environment.disableMetadataSettings;
    }

    showStatusButton(): boolean {
        switch (this.legalFirmIntegration) {
            case IntegrationIdentifier.FILEVINE:
            case IntegrationIdentifier.NEEDLES_NEOS:
            case IntegrationIdentifier.LITIFY:
            case IntegrationIdentifier.SA_CLOUD:
                return true;
            default:
                return false;
        }
    }

    openStatusDialog(): void {
        const dialogRef = this.dialog.open(StatusPopupComponent, {
            width: '400px',
            data: this.status
        });

        dialogRef.componentInstance.newStatusState
            .subscribe((newStatus: IntegrationStatus) => this.status = newStatus);

        dialogRef
            .afterClosed()
            .subscribe(() => {
                dialogRef.componentInstance.newStatusState.unsubscribe()
            });

    }

    reactOnRefreshSettingsEvent(settings: Setting[]) {
        const integration = settings.find(s => s.identifier === 'integration');
        if (integration) {
            this.legalFirmIntegration = IntegrationIdentifier[integration.value];
        }
    }

    @Input('status') set changeStatus(status: IntegrationStatus) {
        if (status != null) {
            this.status = status;
            this.setStatusButtonColor(this.status);
        } else {
            this.status = IntegrationStatus.UNDEPLOYED;
            this.setStatusButtonColor(this.status);
        }
    }

    setStatusButtonColor(status: IntegrationStatus) {
        let statusClass: string;
        switch (status) {
            case IntegrationStatus.STARTED:
                statusClass = 'started';
                break;
            case IntegrationStatus.STOPPED:
                statusClass = 'stopped';
                break;
            case IntegrationStatus.UNDEPLOYED:
                statusClass = 'undeployed';
                break;
        }
        this.statusButtonColor = statusClass;
    }

    private updateLegalFirmList() {
        return (firms: FirmDetails[]) => {
            this.firmLoading = false;
            this.legalFirms = firms;
        };
    }

    refreshStatus() {
        const integrationStatus = new SettingDTO(this.legalFirmService.legalFirmId, 'integration_status', '');
        this.settingService.getSettingValue(integrationStatus)
            .subscribe(integrationStatus => this.changeStatus = IntegrationStatus[integrationStatus]);
    }
}
