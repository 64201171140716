import {Injectable} from '@angular/core';
import {Setting} from '../../models/setting';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SettingDataType} from '../../enums/setting-data-type';

@Injectable({
    providedIn: 'root'
})
export class DynamicFormService {

    public toFormGroup(allSettings: Setting[]): UntypedFormGroup {
        const group: { [key: string]: AbstractControl } = {};

        for (const setting of allSettings) {
            if (setting.dataType === SettingDataType.TOGGLE) {
                group[setting.name] = new UntypedFormControl(setting.value == 'true');
            } else {
                group[setting.name] = new UntypedFormControl(setting.value);
            }
        }

        return new UntypedFormGroup(group);
    }

    public formToInitialValues(initialValues: Setting[], settingsArrayFormGroup: UntypedFormGroup) {
        for (const element of initialValues) {
            const abstractControl = settingsArrayFormGroup
                .get(element.name);

            if (abstractControl) {
                if (element.dataType === SettingDataType.TOGGLE) {
                    abstractControl.setValue(element.value == 'true')
                } else {
                    abstractControl.setValue(element.value)
                }
            }
        }
    }
}
