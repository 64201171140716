import {Component} from '@angular/core';
import {SettingService} from '../../../core/services/setting/setting.service';
import {TabIdentifier} from '../../../core/enums/tab-identifier';
import {SettingFilterService} from '../../../core/services/setting/setting-filter.service';
import {BaseTabComponent} from '../base-tab/base-tab-component';
import {LegalFirmService} from "../../../core/services/legal-firm/legal-firm.service";
import {DynamicFormService} from "../../../core/util/dynamic-form/dynamic-form.service";

@Component({
    selector: 'app-settings-info',
    templateUrl: './settings-info.component.html',
    styleUrls: ['./settings-info.component.scss']
})
export class SettingsInfoComponent extends BaseTabComponent {

    constructor(protected settingService: SettingService,
                protected settingFilterService: SettingFilterService,
                protected legalFirmService: LegalFirmService,
                protected dynamicFormService: DynamicFormService) {
        super(TabIdentifier.SETTINGS, settingService, settingFilterService, legalFirmService, dynamicFormService)
    }

}
