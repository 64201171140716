<ng-container [formGroup]="settingsArrayFormGroup" [ngSwitch]="setting.dataType">
    <mat-icon (click)="openDialog(setting)"
              class="vertical-center pointer"
              color="primary"
              matPrefix>info
    </mat-icon>

    <button *ngSwitchCase="SettingDataType.BUTTON"
            color="primary"
            mat-raised-button>{{setting.name}}</button>

    <mat-form-field *ngSwitchCase="SettingDataType.TEXT_AREA" appearance="outline" class="mat-input-width test">
        <mat-label>{{setting.name}}</mat-label>
        <textarea [formControlName]="setting.name" matInput rows="6">{{setting.value}}</textarea>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="SettingDataType.INPUT" appearance="outline" class="mat-input-width">
        <mat-label>{{setting.name}}</mat-label>
        <input [formControlName]="setting.name" matInput placeholder="Value">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="SettingDataType.TEXT_ARRAY" appearance="outline" class="mat-input-width">
        <mat-label>{{setting.name}}</mat-label>
        <input (blur)="trimTextArrayInput($event, setting)" [formControlName]="setting.name" matInput
               placeholder="Value">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="SettingDataType.PASSWORD" appearance="outline" class="mat-input-width">
        <mat-label>{{setting.name}}</mat-label>
        <input [formControlName]="setting.name" class=" mat-input-wrapper" matInput placeholder="Value"
               type="password"
               value="{{setting.value}}">
    </mat-form-field>

    <div *ngSwitchCase="SettingDataType.SELECT" class="mat-input-width">
        <mat-form-field>
            <mat-label>{{setting.name}}</mat-label>
            <mat-select [formControlName]="setting.name">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let option of setting.displayData.options" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-slide-toggle *ngSwitchCase="SettingDataType.TOGGLE" [checked]="setting.value"
                      [formControlName]="setting.name"
                      class="mat-input-width"
                      color="green"> {{setting.name}}
    </mat-slide-toggle>
</ng-container>


