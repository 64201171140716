import {Component, OnInit} from '@angular/core';
import {Setting} from '../../../core/models/setting';
import {IntegrationIdentifier} from '../../../core/enums/integration-identifier';
import {DynamicFormService} from '../../../core/util/dynamic-form/dynamic-form.service';
import {SettingService} from '../../../core/services/setting/setting.service';
import {TabIdentifier} from '../../../core/enums/tab-identifier';
import {SettingFilterService} from '../../../core/services/setting/setting-filter.service';
import {BaseTabComponent} from '../base-tab/base-tab-component';
import {LegalFirmService} from "../../../core/services/legal-firm/legal-firm.service";

@Component({
    selector: 'app-integration-info',
    templateUrl: './integration-info.component.html',
    styleUrls: ['./integration-info.component.scss']
})
export class IntegrationInfoComponent extends BaseTabComponent implements OnInit {

    allIntegrations = IntegrationIdentifier.getValues();
    selectedIntegration: string;
    allSettings: Setting[] = [];

    constructor(protected settingService: SettingService,
                protected settingFilterService: SettingFilterService,
                protected legalFirmService: LegalFirmService,
                protected dynamicFormService: DynamicFormService) {
        super(TabIdentifier.INTEGRATION, settingService, settingFilterService, legalFirmService, dynamicFormService)
    }

    ngOnInit(): void {
        this.refreshSettingsEvent.subscribe((settings: Setting[]) => {
            const integrationSettingValue = settings.find((setting) => setting.identifier === 'integration').value;
            this.selectedIntegration = IntegrationIdentifier[integrationSettingValue];
            this.allSettings = this.settingFilterService.filterByTabId(settings, this.tabId);
            this.refreshFormGroup(this.allSettings);
        })
    }

    onIntegrationChange(selectedIntegration: string): void {
        this.selectedIntegration = selectedIntegration;
        this.refreshFormGroup(this.allSettings);
    }

    refreshFormGroup(settings: Setting[]): void {
        if (this.selectedIntegration) {
            this.settings = this.settingFilterService.filterByIntegration(settings, this.selectedIntegration);
            this.settingsFormGroup = this.dynamicFormService.toFormGroup(this.settings);
            this.settingsFormGroup.valueChanges.subscribe(() => this.disableFormButtons());
        }
    }

}
