import {SettingMetadata} from '../models/setting-metadata';

export class SettingMetadataDto {

    name: string;
    active: boolean;
    setting_id: string;
    tooltip_description: string;
    tooltip_location: string;
    data_type: string;
    display_data: string;

    static of(settingMetadata: SettingMetadata): SettingMetadataDto {
        const result = new SettingMetadataDto();
        result.name = settingMetadata.name;
        result.setting_id = settingMetadata.settingIdentifier;
        result.active = settingMetadata.active;
        result.tooltip_description = settingMetadata.tooltipDescription;
        result.tooltip_location = settingMetadata.tooltipLocation;
        result.data_type = settingMetadata.dataType;
        result.display_data = JSON.stringify(settingMetadata.displayData);
        return result;
    }
}
