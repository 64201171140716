import {Component, EventEmitter, OnInit} from '@angular/core';
import {TabIdentifier} from '../../core/enums/tab-identifier';
import {Setting} from '../../core/models/setting';
import {SettingMetadataService} from '../../core/services/setting-metadata/setting-metadata.service';
import {FirmDetails} from '../../core/models/firm-details';
import {SettingDTO} from '../../core/dto/setting-dto';
import {SettingService} from '../../core/services/setting/setting.service';
import {IntegrationStatus} from "../../core/enums/integration-status";
import {LegalFirmService} from "../../core/services/legal-firm/legal-firm.service";

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
    TabIdentifier = TabIdentifier;
    allSettingsMetadata: Setting[];
    refreshSettingsEvent: EventEmitter<Setting[]> = new EventEmitter<Setting[]>();
    integrationStatus: IntegrationStatus;

    constructor(private settingMetadataService: SettingMetadataService,
                private settingService: SettingService,
                private legalFirmService: LegalFirmService) {
    }

    ngOnInit(): void {
        this.legalFirmService.refreshLegalFirmSettingsEvent.subscribe(() => this.refreshLegalFirmSettingsEvent())

        this.legalFirmService.legalFirmChangedEvent.subscribe((firmDetails: FirmDetails) => this.loadSettings(firmDetails));
        this.settingMetadataService.getAllMetadata()
            .subscribe((allMeta: Setting[]) => {
                this.allSettingsMetadata = allMeta.filter(setting => setting.active);
                this.refreshSettingsEvent.emit(this.allSettingsMetadata);
            });
    }

    loadSettings(firmDetails: FirmDetails) {
        this.settingService.getAllSettings(firmDetails.legalFirmId)
            .subscribe((settingValues: SettingDTO[]) => {
                const integrationStatusSettingDTO = settingValues.find(s => s.setting_id === 'integration_status');

                if (integrationStatusSettingDTO != null) {
                    const statusSettingValue = integrationStatusSettingDTO.setting_value;
                    this.integrationStatus = IntegrationStatus[statusSettingValue];
                }

                this.allSettingsMetadata = this.fillUpSettingValues(settingValues);
                this.refreshSettingsEvent.emit(this.allSettingsMetadata);
            });
    }

    fillUpSettingValues(settingValues: SettingDTO[]): Setting[] {
        return this.allSettingsMetadata.map(e => {
            const settingDTO = settingValues.find(e1 => e.identifier === e1.setting_id);
            e.value = settingDTO ? settingDTO.setting_value : null;
            return e;
        });
    }

    refreshLegalFirmSettingsEvent() {
        const firmDetails = {legalFirmId: this.legalFirmService.legalFirmId} as FirmDetails;
        this.loadSettings(firmDetails)
    }
}
