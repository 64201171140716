import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

interface DialogData {
    description: string;
    location: string;
    settingName: string;
}

@Component({
    selector: 'app-info-icon-popup',
    templateUrl: './info-icon-popup.component.html',
    styleUrls: ['./info-icon-popup.component.scss']
})
export class InfoIconPopupComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    ngOnInit() {
    }

}
