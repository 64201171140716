import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {CoreModule} from './core/core.module';
import {SettingMetadataService} from './core/services/setting-metadata/setting-metadata.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {LoginCallbackComponent} from './login-callback/login-callback.component';
import {TokenInterceptorService} from './core/services/interceptor/token-interceptor.service';

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        CoreModule
    ],
    declarations: [AppComponent, LoginCallbackComponent],
    bootstrap: [AppComponent],
    providers: [
        SettingMetadataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }
    ]
})
export class AppModule {
}
