<mat-card *ngIf="settings && settings.length > 0" class="main-panel-style">
    <mat-card-header>ARC Information</mat-card-header>
    <app-save-reset-buttons (onResetClick)="formToInitialValues()"
                            (onSaveClick)="onSubmit()"
                            [formButtonsDisabled]="formButtonsDisabled"></app-save-reset-buttons>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="settingsFormGroup">
        <app-info-item *ngFor="let element of settings" [setting]="element"
                       [settingsArrayFormGroup]="settingsFormGroup"
                       id="{{element.name}}"></app-info-item>
    </form>
    <app-save-reset-buttons (onResetClick)="formToInitialValues()"
                            (onSaveClick)="onSubmit()"
                            [formButtonsDisabled]="formButtonsDisabled"></app-save-reset-buttons>
</mat-card>
