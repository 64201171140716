import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpWrapperService {
    readonly apiBaseURL: string = environment.apiBaseUrl;

    constructor(private httpClient: HttpClient) {
    }

    get<T>(uri: string): Observable<T> {
        const url = this.buildUrl(uri);

        return this.httpClient.get<T>(url);
    }

    getString(uri: string): any {
        const url = this.buildUrl(uri);

        return this.httpClient.get<string>(url, {responseType: 'text' as 'json'});
    }

    put<T>(uri: string, body: any): Observable<T> {
        const url = this.buildUrl(uri);

        return this.httpClient.put<T>(url, body);
    }

    private buildUrl(uri: string): string {
        return this.apiBaseURL + uri;
    }

}
