import {Injectable} from '@angular/core';
import {Setting} from '../../models/setting';
import {SettingDTO} from '../../dto/setting-dto';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SettingMetadata} from '../../models/setting-metadata';
import {SettingMetadataDto} from '../../dto/setting-metadata-dto';
import {SettingDTOMapper} from '../../mapper/setting-dto-mapper';
import {HttpWrapperService} from '../http/http-wrapper.service';


@Injectable({
    providedIn: 'root'
})
export class SettingMetadataService {

    constructor(private httpWrapperService: HttpWrapperService) {
    }

    getAllMetadata(): Observable<Setting[]> {
        return this.httpWrapperService.get<SettingMetadataDto[]>('meta')
            .pipe(
                map(items => SettingDTOMapper.toSettingArray(items)
                    .sort((a, b) => (+a.displayData.order === +b.displayData.order) ? 0 : (+a.displayData.order < +b.displayData.order) ? -1 : 1))
            )
    }

    saveSettingMetadata(settingMetadata: SettingMetadata): Observable<SettingMetadata> {
        const body = SettingMetadataDto.of(settingMetadata);

        return this.httpWrapperService.put<SettingDTO>(`meta/${settingMetadata.settingIdentifier}`, body)
            .pipe(
                map(() => settingMetadata));
    }

}

