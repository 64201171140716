import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {HomepageComponent} from './homepage/homepage.component';
import {LegalFirmInfoComponent} from './tab/legal-firm-info/legal-firm-info.component';
import {ArcInfoComponent} from './tab/arc-info/arc-info.component';
import {SharedModule} from '../shared/shared.module';
import {SettingsInfoComponent} from './tab/settings-info/settings-info.component';
import {AccountingInfoComponent} from './tab/accounting-info/accounting-info.component';
import {IntegrationInfoComponent} from './tab/integration-info/integration-info.component';
import {LegalFirmControlBarComponent} from './legal-firm-control-bar/legal-firm-control-bar.component';
import {InfoItemComponent} from './info-item/info-item.component';
import {InfoIconPopupComponent} from './popup/info-icon-popup/info-icon-popup.component';
import {NewLegalFirmPopupComponent} from './popup/new-legal-firm-popup/new-legal-firm-popup.component';
import {SearchFirmDetailsFilterPipe} from '../core/pipe/search-filter.pipe';
import {StatusPopupComponent} from './popup/status-popup/status-popup.component';


@NgModule({
    declarations: [HomepageComponent, LegalFirmInfoComponent, ArcInfoComponent, SettingsInfoComponent, AccountingInfoComponent, IntegrationInfoComponent, LegalFirmControlBarComponent, InfoItemComponent, InfoIconPopupComponent, NewLegalFirmPopupComponent, SearchFirmDetailsFilterPipe, StatusPopupComponent],
    imports: [
        CommonModule,
        SharedModule,
        HomeRoutingModule
    ]
})
export class HomeModule {
}
