import {SettingDTO} from '../dto/setting-dto';
import {FirmDetails} from '../models/firm-details';
import {SettingMetadataDto} from '../dto/setting-metadata-dto';
import {DisplayData} from '../models/display-data';
import {Setting} from '../models/setting';
import {SettingMetadata} from '../models/setting-metadata';
import {SettingDataType} from '../enums/setting-data-type';

export class SettingDTOMapper {

    public static toFirmDetailsList(settingDTOs: SettingDTO[]): FirmDetails[] {
        return settingDTOs.map(settingDTO => new FirmDetails(settingDTO))
    }

    public static toSettingArray(settingMetadataDTOs: SettingMetadataDto[]): Setting[] {
        return settingMetadataDTOs
            .map(settingMetadataDTO => this.toSetting(settingMetadataDTO));
    }

    public static toSetting(settingMetadataDTO: SettingMetadataDto): Setting {
        const setting: Setting = new Setting();

        setting.name = settingMetadataDTO.name;
        setting.active = settingMetadataDTO.active;
        setting.identifier = settingMetadataDTO.setting_id;
        const displayData: DisplayData = JSON.parse(settingMetadataDTO.display_data);
        setting.dataType = SettingDataType.valueOf(settingMetadataDTO.data_type);
        setting.displayData = displayData;
        setting.tooltipDescription = settingMetadataDTO.tooltip_description;
        setting.tooltipLocation = settingMetadataDTO.tooltip_location;

        return setting;
    }

    public static toSettingMetadata(setting: Setting): SettingMetadata {
        const settingMetadata = new SettingMetadata();
        settingMetadata.name = setting.name;
        settingMetadata.settingIdentifier = setting.identifier;
        settingMetadata.active = setting.active;
        settingMetadata.dataType = setting.dataType;
        settingMetadata.displayData = setting.displayData;
        settingMetadata.tooltipDescription = setting.tooltipDescription;
        settingMetadata.tooltipLocation = setting.tooltipLocation;

        return settingMetadata;
    }
}
