<div mat-dialog-content>
    <div class="popup-header">{{data.settingName}}</div>
    <form class="popup-form">
        <mat-form-field class="popup-field-full-width">
            <mat-label>Description</mat-label>
            <textarea mat-autosize matInput placeholder="Description" readonly>{{data.description}}</textarea>
        </mat-form-field>

        <mat-form-field class="popup-field-full-width">
            <mat-label>Location</mat-label>
            <textarea mat-autosize matInput placeholder="Location" readonly>{{data.location}}</textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button [mat-dialog-close]="data" color="primary" mat-raised-button>OK</button>
</div>
