import {DisplayData} from './display-data';
import {SettingDataType} from '../enums/setting-data-type';

export class SettingMetadata {
    name: string;
    settingIdentifier: string;
    active: boolean;
    dataType: SettingDataType;
    displayData: DisplayData;
    tooltipDescription: string;
    tooltipLocation: string;
}
