import {DisplayData} from './display-data';
import {SettingDataType} from '../enums/setting-data-type';

export class Setting {

    name: string;
    value?: any;
    identifier: string;
    active: boolean;
    dataType: SettingDataType;
    displayData: DisplayData;
    tooltipDescription: string;
    tooltipLocation: string;

    constructor(name?: string, settingId?: string, dataType?: SettingDataType) {
        this.name = name;
        this.identifier = settingId;
        this.dataType = dataType ? dataType : SettingDataType.INPUT;
        this.active = false;
    }
}
