// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// import * as env from './env.json';
declare var require: any
export const environment = {
    production: false,
    apiBaseUrl: 'https://api-' + require('./env.json').HoustonDomainName + '/',
    domainName: require('./env.json').CognitoHostedUIDomainName,
    region: require('./env.json').StacksRegion,
    clientId: require('./env.json').CognitoUserPoolClientID,
    redirectUrl: 'https://' + require('./env.json').HoustonDomainName + '/auth-callback',
    disableMetadataSettings: false
  };